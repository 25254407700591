import './App.css';
import logo from './SparkSigma_logo_TM.png';
import hero from './spark-sigma-space-hero.jpg';

function App() {
  return (
    <div className="App">
      <img className="hero" src={hero}  alt="sparksigma hero" />
      <div className="logo-container">
        <img className="logo" src={logo}  alt="sparksigma logo" height="80px" />
      </div>
      <div className="home-contents" style={{paddingTop: "100px"}}>
        
         <h1>Bringing new technologies to life</h1>
         <p style={{fontSize: "17px"}}>Spark Sigma are experts in technology commercialization. From the lab to the marketplace.</p>
         <br/>
         <a className="contact" href="mailto:contact@sparksigma.com">Contact Us</a>
         <br/>
         <br/>
         <br/>
         <br/>
         <br/>
         <br/>

      </div>
    </div>
  );
}

export default App;
